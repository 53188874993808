import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import ExerciseCreate from "./ExerciseCreate";
import ExerciseUpdate from "./ExerciseUpdate";
import Exercises from "./Exercises";

const ExercisesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Exercises />
      </Route>
      <Route exact path={`${path}/new`}>
        <ExerciseCreate />
      </Route>
      <Redirect
        exact
        path={`${path}/:exerciseId`}
        to={`${path}/:exerciseId/details`}
      />
      <Route path={`${path}/:exerciseId`}>
        <ExerciseUpdate />
      </Route>
    </Switch>
  );
};

export default ExercisesRouter;
