import React from "react";

import firebase from "firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import * as Layout from "../../components/Layout";
import * as AppRoute from "../../components/Route";

import AuthProvider from "../../context/Auth";

import * as Page from "../../pages";

const queryClient = new QueryClient();

const publicPaths = ["/login"];

const PublicRouter = React.memo(() => (
  <Layout.Public>
    <Switch>
      <Route exact path="/login">
        <Page.Login />
      </Route>
      <Redirect to="/login" />
    </Switch>
  </Layout.Public>
));

const PrivateRouter = React.memo(() => {
  return (
    <Layout.Private>
      <Switch>
        <Route exact path="/">
          <Page.Dashboard />
        </Route>
        <Route path="/exercise">
          <Page.Exercises />
        </Route>
        <Route path="/workout">
          <Page.Workouts />
        </Route>
        <Route path="/user">
          <Page.Users />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Layout.Private>
  );
});

const Router = () => {
  const [, loading] = useAuthState(firebase.auth());

  if (loading) {
    return null;
  }

  return (
    <BrowserRouter>
      <Switch>
        <AppRoute.Public path={publicPaths}>
          <PublicRouter />
        </AppRoute.Public>
        <AppRoute.Private path="/">
          <PrivateRouter />
        </AppRoute.Private>
      </Switch>
    </BrowserRouter>
  );
};

const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default Root;
