import React from "react";

import { Switch, Route, useRouteMatch } from "react-router-dom";

import User from "./User";
import Users from "./Users";

const UsersRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Users />
      </Route>
      <Route exact path={`${path}/new`}>
        <User />
      </Route>
      <Route path={`${path}/:userId`}>
        <User />
      </Route>
    </Switch>
  );
};

export default UsersRouter;
