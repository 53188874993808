import React from "react";

import { Switch, Route, useRouteMatch } from "react-router-dom";

import Workout from "./Workout";
import Workouts from "./Workouts";

const WorkoutsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Workouts />
      </Route>
      <Route exact path={`${path}/new`}>
        <Workout />
      </Route>
      <Route path={`${path}/:workoutId`}>
        <Workout />
      </Route>
    </Switch>
  );
};

export default WorkoutsRouter;
