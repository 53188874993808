import React, { useCallback, useEffect } from "react";
import {
  Button,
  Form,
  Layout,
  message,
  Row,
  Skeleton,
  Space,
  Switch as AntSwitch,
} from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import firebase from "firebase";
import { omit } from "lodash";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
import {
  Link,
  useHistory,
  useParams,
  useRouteMatch,
  Route,
  Switch,
} from "react-router-dom";

import PageHeader from "../../../components/PageHeader";
import { useNextExercise, usePrevExercise } from "../../../context/Exercises";
import { FormProvider } from "../../../context/Form";
import Forms from "./Forms";

interface InputVariables extends Partial<IExercise> {
  uploads?: UploadFile[];
  fitness?: {
    google?: {
      exercise?: string;
      resistance_type?: string;
    };
  };
}

const ExerciseUpdate = React.memo(() => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { exerciseId } = useParams<{
    exerciseId?: string;
  }>();

  const onSubmit = useCallback(
    (input: InputVariables) => {
      const { uploads, ...variables } = input;

      let inputVariables = { ...variables };

      const { media } = inputVariables;

      if (!inputVariables?.fitness?.google?.exercise) {
        inputVariables = {
          ...inputVariables,
          fitness: omit(inputVariables?.fitness, ["google"]),
        };
      }

      if (exerciseId) {
        inputVariables = {
          ...inputVariables,
          sharedWith: ["everyone"],
          published: media ? true : false,
        };

        return firebase
          .firestore()
          .doc(`exercises/${exerciseId}`)
          .set({ ...inputVariables }, { merge: true })
          .then(() => {
            message.success(t("exercise:message.success.edit.title"));
          })
          .catch((error) => {
            message.error(error.message);
          });
      }
    },
    [exerciseId, t]
  );

  const [exerciseData, loading] = useDocumentDataOnce<IExercise>(
    exerciseId
      ? firebase.firestore().doc(`exercises/${exerciseId}`)
      : undefined,
    { idField: "id" }
  );

  const {
    location: { pathname },
  } = useHistory();

  const match = useRouteMatch();
  const prevExercise = usePrevExercise(exerciseId);
  const nextExercise = useNextExercise(exerciseId);

  useEffect(() => {
    if (exerciseData) {
      form.setFieldsValue(exerciseData);
    }
  }, [exerciseData]);

  return (
    <FormProvider
      form={form}
      layout="vertical"
      requiredMark="optional"
      onFinish={onSubmit}
    >
      <PageHeader
        title={exerciseData?.name || t("exercise:title")}
        breadcrumb={{
          routes: [
            {
              path: "/exercise",
              breadcrumbName: t("exercises:title"),
            },
            {
              breadcrumbName: exerciseData?.name || t("exercise:title"),
              path: pathname,
            },
          ],
        }}
        extra={[
          <Space key="asd">
            <Form.Item
              noStyle
              key="published"
              name={"published"}
              rules={[{ required: false }]}
              valuePropName="checked"
            >
              <AntSwitch
                checkedChildren={t("exercise:update.published.true")}
                unCheckedChildren={t("exercise:update.published.false")}
              />
            </Form.Item>
            {prevExercise ? (
              <Link
                to={pathname.replace(exerciseId ?? "", prevExercise.id)}
                key="prev"
                aria-disabled={!prevExercise}
              >
                <Button type="default">{t("exercise:button.previous")}</Button>
              </Link>
            ) : (
              <Button type="default" key="prev" disabled>
                {t("exercise:button.previous")}
              </Button>
            )}
            {nextExercise ? (
              <Link
                to={pathname.replace(exerciseId ?? "", nextExercise.id)}
                key="next"
                aria-disabled={!nextExercise}
              >
                <Button type="default">{t("exercise:button.next")}</Button>
              </Link>
            ) : (
              <Button type="default" key="next" disabled>
                {t("exercise:button.next")}
              </Button>
            )}
          </Space>,
        ]}
      />

      <Layout.Content className="site-layout-content">
        <Skeleton loading={loading}>
          <Switch>
            <Route path={`${match.path}/:lang(sr)`}>
              <Forms />
            </Route>
            <Route path={match.path}>
              <Forms />
            </Route>
          </Switch>
        </Skeleton>
      </Layout.Content>
    </FormProvider>
  );
});

export default ExerciseUpdate;
