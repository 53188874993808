import React from "react";
import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import firebase from "firebase";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

const { Title } = Typography;

const InstructionsForm = React.memo(() => {
  const { t } = useTranslation();

  const { lang } = useParams<{ lang?: string }>();

  const { exerciseId } = useParams<{
    exerciseId?: string;
  }>();

  const [exerciseData] = useDocumentDataOnce<IExercise>(
    exerciseId
      ? firebase.firestore().doc(`exercises/${exerciseId}`)
      : undefined,
    { idField: "id" }
  );

  return (
    <>
      <Title level={5}>{t("exercise:section.title.instructions")}</Title>
      <Divider className="mt-0" />
      <Form.List
        name={lang ? ["i18n", lang, "instructions"] : ["instructions"]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row wrap={false} gutter={16}>
                <Col flex={1} style={{ display: "flex", marginBottom: 20 }}>
                  <Form.Item
                    {...field}
                    label={t("exercise:input.label.instruction", {
                      count: index + 1,
                    })}
                    rules={[
                      {
                        required: true,
                        message: t("exercise:input.error.instruction.empty"),
                      },
                    ]}
                    noStyle
                  >
                    <Input.TextArea rows={3} />
                  </Form.Item>
                  <Form.Item noStyle>
                    {lang === "sr" ? (
                      <Input.TextArea
                        rows={3}
                        value={[
                          `(${t(`language:english`)})`,
                          exerciseData?.instructions?.[index],
                        ]
                          .filter(Boolean)
                          .join(" ")}
                        disabled={true}
                        style={{ marginLeft: 20, cursor: "default" }}
                      />
                    ) : (
                      <Input.TextArea
                        rows={3}
                        value={[
                          `(${t(`language:serbian`)})`,
                          exerciseData?.i18n?.sr?.instructions?.[index],
                        ]
                          .filter(Boolean)
                          .join(" ")}
                        disabled={true}
                        style={{ marginLeft: 20, cursor: "default" }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col style={{ alignItems: "center", display: "flex" }}>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add("")}
                block
                icon={<PlusOutlined />}
              >
                {t("exercise:button.instruction.add")}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ textAlign: "center" }}
                type="primary"
                htmlType="submit"
              >
                {t("exercise:button.submitEditing")}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
});

export default InstructionsForm;
