import { Tabs } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import Exercise from "../ExerciseCreate";
import DetailsForm from "./DetailsForm";
import InstructionsForm from "./InstructionsForm";
import MediaForm from "./MediaForm";

const { TabPane } = Tabs;

const Forms = React.memo(() => {
  const { t } = useTranslation();

  const match = useRouteMatch<{ exerciseId: string; lang: string }>();

  const location = useLocation();

  return (
    <>
      <Tabs activeKey={match.params.lang}>
        <TabPane
          tab={
            <Link to={`/exercise/${match.params.exerciseId}/details`}>
              {t("language:english")}
            </Link>
          }
        />
        <TabPane
          key="sr"
          tab={
            <Link to={`/exercise/${match.params.exerciseId}/sr/details`}>
              {t("language:serbian")}
            </Link>
          }
        />
      </Tabs>
      <Switch>
        <Tabs activeKey={location.pathname}>
          <TabPane
            tab={
              <Link to={`${match.url}/details`}>
                {t("exercise:tab.details")}
              </Link>
            }
            key={`${match.url}/details`}
          >
            <Route path={`${match.path}/details`}>
              <DetailsForm />
            </Route>
          </TabPane>
          <TabPane
            tab={
              <Link to={`${match.url}/instructions`}>
                {t("exercise:tab.instructions")}
              </Link>
            }
            key={`${match.url}/instructions`}
          >
            <Route path={`${match.path}/instructions`}>
              <InstructionsForm />
            </Route>
          </TabPane>
          <TabPane
            tab={
              <Link to={`${match.url}/media`}>{t("exercise:tab.media")}</Link>
            }
            key={`${match.url}/media`}
          >
            <Route path={`${match.path}/media`}>
              <MediaForm />
            </Route>
          </TabPane>
        </Tabs>
      </Switch>
    </>
  );
});

export default Forms;
