import React, { useContext, useState } from "react";

import { Form as AntForm } from "antd";
import { FormInstance, FormProps } from "antd/lib/form";

export type Props = FormProps & {
  disabled?: boolean;
};

// @ts-ignore
const FormContext = React.createContext<{
  form: FormInstance;
  disabled?: boolean;
}>();

export const useFormContext = () => useContext(FormContext);
export const useSharedForm = () => {
  const { form } = useFormContext();

  return form;
};

export const useFormDisabled = () => {
  const { disabled } = useFormContext();

  return disabled;
};

const FormProvider: React.FC<Props> = ({
  form: initialForm,
  children,
  disabled,
  ...rest
}) => {
  const [form] = AntForm.useForm(initialForm);

  return (
    <FormContext.Provider value={{ form, disabled }}>
      <AntForm form={form} {...rest}>
        {children}
      </AntForm>
    </FormContext.Provider>
  );
};

export { FormProvider };
