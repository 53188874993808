import React, { useCallback, useMemo, useState } from "react";

import firebase from "firebase";
import map from "lodash/map";

import { Button, Layout, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import type { SortableContainerProps, SortEnd } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";

import { useMutation } from "react-query";
import { arrayMoveImmutable } from "array-move";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCollectionData } from "react-firebase-hooks/firestore";

import PageHeader from "../../../components/PageHeader";

const ActionDelete = ({ data: { id } }: { data: IWorkout }) => {
  const [loading, setLoading] = useState(false);

  const onDelete = () => {
    setLoading(true);

    firebase
      .firestore()
      .doc(`workouts/${id}`)
      .delete()
      .finally(() => setLoading(false));
  };

  const { t } = useTranslation();

  return (
    <Popconfirm
      placement="topLeft"
      title="Are you sure you want to delete this workout?"
      onConfirm={() => onDelete()}
      okText="Delete"
      okType="danger"
      cancelText="Cancel"
      disabled={loading}
    >
      <Button type="text" danger disabled={loading}>
        {t("exercises:table.button.delete")}
      </Button>
    </Popconfirm>
  );
};

const Workouts = React.memo(() => {
  const { t } = useTranslation(["workouts"]);

  const [data, loading] = useCollectionData<IWorkout>(
    firebase.firestore().collection("workouts").orderBy("index", "asc"),
    {
      idField: "id",
    }
  );

  const [dataSource, setDataSource] = useState<IWorkout[] | undefined>(
    undefined
  );

  const isSorting = useMemo(
    () => Array.isArray(dataSource) && dataSource.length > 0,
    [dataSource]
  );

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const SortableItem = SortableElement(
    (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
  );

  const SortableBody = SortableContainer(
    (props: React.HTMLAttributes<HTMLTableSectionElement>) => (
      <tbody {...props} />
    )
  );

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (!dataSource) {
      return;
    }

    if (oldIndex === newIndex) {
      return;
    }

    const newData = arrayMoveImmutable(
      dataSource.slice(),
      oldIndex,
      newIndex
    ).filter(Boolean);

    setDataSource(newData);
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<{
    "data-row-key": number;
    className: string;
    style: any;
  }> = ({ className, style, ...restProps }) => {
    const index =
      dataSource?.findIndex((x) => x.index === restProps["data-row-key"]) ?? 0;

    return <SortableItem index={index} {...restProps} />;
  };

  const columns = useMemo((): ColumnsType<IWorkout> => {
    let nextColumns: ColumnsType<IWorkout> = [
      {
        dataIndex: "index",
        title: t("workouts:table.header.index"),
      },
      {
        dataIndex: "name",
        title: t("workouts:table.header.name"),
        render: (value: string, data) => (
          <Link to={`/workout/${data.id}`}>{value}</Link>
        ),
      },

      {
        title: t("workouts:table.header.actions"),
        key: "actions",
        render: (_value: any, data) => (
          <Space size="middle">
            <ActionDelete data={data} />
          </Space>
        ),
      },
    ];

    if (isSorting) {
      nextColumns = [
        {
          title: "Sort",
          dataIndex: "sort",
          width: 30,
          className: "drag-visible",
          render: () => <DragHandle />,
        },
        ...nextColumns,
      ];
    }

    return nextColumns;
  }, [DragHandle, isSorting, t]);

  const { mutateAsync: onUpdateSort, isLoading: isUpdatingSort } = useMutation(
    () => {
      const batch = firebase.firestore().batch();

      dataSource?.forEach((workout, index) => {
        batch.update(firebase.firestore().doc(`workouts/${workout.id}`), {
          index,
        });
      });

      return batch.commit();
    },
    {
      onSuccess: () => {
        setDataSource(undefined);
      },
    }
  );

  const onToggleSort = useCallback(() => {
    if (!isSorting) {
      return setDataSource(map(data, (item, index) => ({ ...item, index })));
    }

    return onUpdateSort();
  }, [data, isSorting, onUpdateSort]);

  return (
    <>
      <PageHeader
        title={t("workouts:title")}
        breadcrumb={{
          routes: [
            {
              path: "/",
              breadcrumbName: t("workouts:title"),
            },
          ],
        }}
        extra={[
          <Space key="sorting">
            <Button
              type="primary"
              onClick={onToggleSort}
              loading={isUpdatingSort}
              // icon={<OrderedListOutlined />}
            >
              {isSorting
                ? t("workouts:button.saveReorder")
                : t("workouts:button.reorder")}
            </Button>
            {isSorting && (
              <Button
                type="primary"
                danger
                onClick={() => setDataSource(undefined)}
              >
                {t("workouts:button.cancelReorder")}
              </Button>
            )}
          </Space>,
          <Link key="workout-create" to="/workout/new">
            <Button type="primary">{t("workouts:button.create")}</Button>
          </Link>,
        ]}
      />

      <Layout.Content className="site-layout-content">
        <Table
          rowKey="index"
          columns={columns}
          dataSource={dataSource ?? data}
          loading={loading}
          scroll={{ x: true }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </Layout.Content>
    </>
  );
});

export default Workouts;
