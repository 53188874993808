export const ResistanceType = {
  UNKNOWN: 0,
  BARBELL: 1,
  CABLE: 2,
  DUMBBELL: 3,
  KETTLEBELL: 4,
  MACHINE: 5,
  BODY: 6,
};

export const WorkoutExercise = {
  PUSHUP: "pushup",
  CLOSE_GRIP_PUSHUP: "pushup.close_grip",
  PIKE_PUSHUP: "pushup.pike",
  BENCH_PRESS: "press.bench",
  INCLINE_BENCH_PRESS: "press.bench.incline",
  DECLINE_BENCH_PRESS: "press.bench.decline",
  CLOSE_GRIP_BENCH_PRESS: "press.bench.close_grip",
  FLY: "fly",
  PULLOVER: "pullover",
  DIP: "dip",
  TRICEPS_DIP: "dip.triceps",
  CHEST_DIP: "dip.chest",
  SHOULDER_PRESS: "press.shoulder",
  PIKE_PRESS: "press.pike",
  ARNOLD_PRESS: "press.shoulder.arnold",
  MILITARY_PRESS: "press.shoulder.military",
  LATERAL_RAISE: "raise.lateral",
  FRONT_RAISE: "raise.front",
  REAR_LATERAL_RAISE: "raise.lateral.rear",
  CLEAN: "clean",
  CLEAN_JERK: "clean.jerk",
  HANG_CLEAN: "clean.hang",
  POWER_CLEAN: "clean.power",
  HANG_POWER_CLEAN: "clean.hang_power",
  ROW: "row",
  UPRIGHT_ROW: "row.upright",
  HIGH_ROW: "row.high",
  PULLUP: "pullup",
  CHINUP: "chinup",
  PULLDOWN: "pulldown",
  SHRUG: "shrug",
  BACK_EXTENSION: "back_extension",
  GOOD_MORNING: "good_morning",
  BICEP_CURL: "curl.bicep",
  TRICEPS_EXTENSION: "triceps_extension",
  JM_PRESS: "press.jm",
  SQUAT: "squat",
  LEG_PRESS: "leg_press",
  LEG_CURL: "leg_curl",
  LEG_EXTENSION: "leg_extension",
  WALL_SIT: "wall_sit",
  STEP_UP: "step_up",
  DEADLIFT: "deadlift",
  SINGLE_LEG_DEADLIFT: "deadlift.single_leg",
  STRAIGHT_LEG_DEADLIFT: "deadlift.straight_leg",
  RDL_DEADLIFT: "deadlift.rdl",
  LUNGE: "lunge",
  REAR_LUNGE: "lunge.rear",
  SIDE_LUNGE: "lunge.side",
  SITUP: "situp",
  CRUNCH: "crunch",
  LEG_RAISE: "leg_raise",
  HIP_RAISE: "hip_raise",
  V_UPS: "vups",
  TWISTING_SITUP: "situp.twisting",
  TWISTING_CRUNCH: "crunch.twisting",
  PLANK: "plank",
  SIDE_PLANK: "plank.side",
  HIP_THRUST: "hip_thrust",
  SINGLE_LEG_HIP_BRIDGE: "bridge.hip.single_leg",
  HIP_EXTENSION: "hip_extension",
  RUSSIAN_TWIST: "russian_twist",
  SWING: "swing",
  CALF_RAISE: "calf_raise",
  STANDING_CALF_RAISE: "calf_raise.standing",
  SEATED_CALF_RAISE: "calf_raise.seated",
  CALF_PRESS: "calf_press",
  THRUSTER: "thruster",
  JUMPING_JACK: "jumping_jack",
  BURPEE: "burpee",
  HIGH_KNEE_RUN: "run.high_knee",
};
