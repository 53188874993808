import React from "react";
import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";

import * as Select from "../../../components/Select";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const { Title } = Typography;

const DetailsForm = React.memo(() => {
  const { t } = useTranslation();

  const { lang } = useParams<{ lang?: string }>();

  const getFieldName = (key: string[]) => {
    if (!lang) {
      return key;
    }

    return ["i18n", lang, ...key];
  };

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={12} xxl={8}>
        <Title level={5}>{t("exercise:section.title.details")}</Title>
        <Divider className="mt-0" />
        <Form.Item
          label={t("exercise:input.label.name")}
          name={getFieldName(["name"])}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        {!lang && (
          <>
            <Form.Item
              label={t("exercise:input.label.exerciseType")}
              name={getFieldName(["exerciseType"])}
              rules={[{ required: true }]}
            >
              <Select.ExerciseType showSearch />
            </Form.Item>
            <Form.Item
              label={t("exercise:input.label.equipment")}
              name={getFieldName(["equipment"])}
              rules={[{ required: true }]}
            >
              <Select.ExerciseEquipment showSearch />
            </Form.Item>
            <Form.Item
              label={t("exercise:input.label.muscleGroup")}
              name={getFieldName(["muscleGroup"])}
              rules={[{ required: true }]}
            >
              <Select.ExerciseMuscleGroup showSearch />
            </Form.Item>
          </>
        )}
      </Col>
      {!lang && (
        <Col xs={24} sm={24} md={12} xxl={8}>
          <Title level={5}>{t("exercise:section.title.googleFit")}</Title>
          <Divider className="mt-0" />
          <Form.Item
            label={t("exercise:input.label.fitness.google.exercise")}
            name={getFieldName(["fitness", "google", "exercise"])}
            rules={[{ required: false }]}
          >
            <Select.GoogleFitExercise showSearch />
          </Form.Item>
          <Form.Item
            label={t("exercise:input.label.fitness.google.resistance_type")}
            name={getFieldName(["fitness", "google", "resistance_type"])}
            rules={[{ required: false }]}
          >
            <Select.GoogleFitResistanceType showSearch />
          </Form.Item>
        </Col>
      )}
      <Col xs={24} sm={24} md={24} xxl={24}>
        <Form.Item>
          <Button
            style={{ textAlign: "center" }}
            type="primary"
            htmlType="submit"
          >
            {t("exercise:button.submitEditing")}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
});

export default DetailsForm;
