import React, { useCallback, useEffect, useMemo, useState } from "react";

import firebase from "firebase";

import map from "lodash/map";
import find from "lodash/find";
import times from "lodash/times";

import {
  Layout,
  Form,
  Row,
  Col,
  Input,
  Button,
  Typography,
  Divider,
  InputNumber,
  message,
  Skeleton,
} from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { useHistory, useParams, useRouteMatch, Link } from "react-router-dom";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import * as Select from "../../../components/Select";
import PageHeader from "../../../components/PageHeader";
import { FormProvider, useSharedForm } from "../../../context/Form";

import { ExerciseTypeFields as ExerciseTypeFields } from "../../../config/const";
import { useExercises } from "../../../context/Exercises";

import { Tabs } from "antd";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const ExerciseSetsList = ({
  field: exerciseField,
  remove: removeExercise,
}: {
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
}) => {
  const { t } = useTranslation();

  const form = useSharedForm();

  const exercise = form.getFieldValue([
    "exercises",
    exerciseField.name,
  ]) as IExercise;

  const exerciseTitle = exercise?.name as string;
  const exerciseType = exercise?.exerciseType as string;

  const exerciseSetFields = ExerciseTypeFields[exerciseType];

  return (
    <>
      <Text className="exercise">{exerciseTitle}</Text>
      <Divider className="mt-0" />
      <Form.List name={[exerciseField.name, "notes"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row wrap={false} gutter={16}>
                <Col flex={1}>
                  <Form.Item
                    {...field}
                    rules={[
                      {
                        required: true,
                        message: "Korak ne moze biti prazan",
                      },
                    ]}
                  >
                    <Input.TextArea rows={2} />
                  </Form.Item>
                </Col>
                <Col style={{ alignItems: "center", display: "flex" }}>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add("")}
                block
                icon={<PlusOutlined />}
              >
                {t("workout:input:label:addNote")}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.List name={[exerciseField.name, "sets"]}>
        {(fields, { add, remove }) => {
          const onInsertSet = () => {
            const previous = form.getFieldValue([
              "exercises",
              exerciseField.name,
              "sets",
              fields.length - 1,
            ]);

            add(previous);
          };

          return (
            <>
              {fields.map((field, _index, list) => {
                const onRemove = () => {
                  remove(field.name);

                  if (list.length === 1) {
                    removeExercise(exerciseField.name);
                  }
                };

                return (
                  <Row wrap={false} gutter={8}>
                    <Col flex={1}>
                      <Form.Item
                        {...field}
                        label={t("workout:input.label.pause")}
                        name={[field.name, "pause"]}
                        fieldKey={[field.fieldKey, "pause"]}
                        rules={[{ required: true }]}
                      >
                        <InputNumber className="w-100" />
                      </Form.Item>
                    </Col>
                    {map(exerciseSetFields, (key) => (
                      <Col flex={1}>
                        <Form.Item
                          {...field}
                          label={t(`workout:input.label.${key}`, {
                            unit: "metric",
                          })}
                          name={[field.name, key]}
                          fieldKey={[field.fieldKey, key]}
                          rules={[{ required: true }]}
                        >
                          <InputNumber className="w-100" />
                        </Form.Item>
                      </Col>
                    ))}
                    <Col style={{ alignItems: "center", display: "flex" }}>
                      <MinusCircleOutlined onClick={onRemove} />
                    </Col>
                  </Row>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={onInsertSet}
                  block
                  icon={<PlusOutlined />}
                >
                  {t("workout:button.exercise.set.add")}
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

const ExercisesList = () => {
  const { t } = useTranslation();

  const exercises = useExercises();

  const [selected, setSelected] = useState<IExercise>();

  const onChange = (value: string) => {
    setSelected(find(exercises, { name: value }));
  };

  const {
    replace,
    location: { pathname },
  } = useHistory();

  return (
    <>
      {pathname.includes("/sr") ? null : (
        <Form.List name="exercises">
          {(fields, { add, remove }) => {
            const onExerciseAdd = () => {
              setSelected(undefined);

              add({
                ...selected,
                sets: times(1, () => ({})),
              });
            };

            return (
              <>
                <Form.Item
                  label={t("workout:input.label.exercise")}
                  requiredMark={false}
                >
                  <Select.Exercises
                    showSearch
                    onChange={onChange}
                    value={selected?.name}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={onExerciseAdd}
                    block
                    disabled={!selected}
                    icon={<PlusOutlined />}
                  >
                    {t("workout:button.exercise.add")}
                  </Button>
                </Form.Item>
                {fields.reverse().map((field) => (
                  <ExerciseSetsList field={field} remove={remove} />
                ))}
              </>
            );
          }}
        </Form.List>
      )}
    </>
  );
};

const Workout = React.memo(() => {
  const { t } = useTranslation();

  const { workoutId } = useParams<{
    workoutId?: string;
  }>();

  const match = useRouteMatch<{ workoutId: string; lang: string }>();

  const [workoutData, loading] = useDocumentDataOnce(
    workoutId ? firebase.firestore().doc(`workouts/${workoutId}`) : undefined
  );

  const {
    replace,
    location: { pathname },
  } = useHistory();

  const [form] = Form.useForm();

  useEffect(() => {
    if (workoutData) {
      form.setFieldsValue(workoutData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutData]);

  const getFieldName = (key: string[]) => {
    if (!pathname.includes("/sr")) {
      return key;
    }

    return ["i18n", "sr", ...key];
  };

  const getOrderIndex = useCallback(() => {
    return firebase
      .firestore()
      .collection("workouts")
      .orderBy("index", "desc")
      .limit(1)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return 0;
        }

        return snapshot.docs[0].data().index + 1;
      })
      .catch(() => 0);
  }, []);

  const onSubmit = useCallback(
    async (variables: any) => {
      map(variables.exercises, (key) => {
        if (key.notes === undefined) {
          key.notes = "";
        }
      });
      if (variables.i18n) {
        if (variables.i18n.sr.note == undefined) {
          variables.i18n.sr.note = "";
        }
      }

      if (!workoutId) {
        const index = await getOrderIndex();

        return firebase
          .firestore()
          .collection("workouts")
          .add({ ...variables, index })
          .then((snapshot) => {
            message.success(t("workout:message.success.create.title"));
            return replace(`/workout/${snapshot.id}`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      }

      return firebase
        .firestore()
        .doc(`workouts/${workoutId}`)
        .update(variables)
        .then(() => {
          message.success(t("workout:message.success.edit.title"));
        })
        .catch((error) => {
          message.error(error.message);
        });
    },
    [workoutId, replace, t]
  );

  return (
    <>
      <PageHeader
        title={workoutData?.name || t("workout:title")}
        breadcrumb={{
          routes: [
            {
              path: "/workout",
              breadcrumbName: t("workouts:title"),
            },
            {
              breadcrumbName: workoutData?.name || t("workout:title"),
              path: pathname,
            },
          ],
        }}
      />

      <Layout.Content className="site-layout-content">
        <Skeleton loading={loading}>
          <FormProvider
            layout="vertical"
            requiredMark="optional"
            onFinish={onSubmit}
            form={form}
            initialValues={{
              note: "",
              name: "",
              exercises: [],
              unit: "metric",
            }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} xxl={12}>
                {workoutId ? (
                  <Tabs activeKey={match.params.lang}>
                    <TabPane
                      tab={
                        <Link to={`/workout/${match.params.workoutId}/details`}>
                          {t("language:english")}
                        </Link>
                      }
                    />
                    <TabPane
                      key="sr"
                      tab={
                        <Link
                          to={`/workout/${match.params.workoutId}/sr/details`}
                        >
                          {t("language:serbian")}
                        </Link>
                      }
                    />
                  </Tabs>
                ) : null}
                <Title level={5}>{t("workout:section.title.details")}</Title>
                <Divider className="mt-0" />
                <Form.Item
                  label={t("workout:input.label.name")}
                  name={getFieldName(["name"])}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("workout:input.label.note")}
                  name={getFieldName(["note"])}
                  rules={[{ required: false }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              {pathname.includes("/sr") ? null : (
                <Col xs={24} sm={24} md={24} xxl={12}>
                  <Title level={5}>
                    {t("workout:section.title.exercises")}
                  </Title>
                  <Divider className="mt-0" />
                  <ExercisesList />
                </Col>
              )}
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t("workout:button.submit")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </FormProvider>
        </Skeleton>
      </Layout.Content>
    </>
  );
});

export default Workout;
