import React, { useCallback, useState } from "react";

import firebase from "firebase";
import omit from "lodash/omit";

import { Layout, Tabs, Button, message, Skeleton } from "antd";
import { UploadFile } from "antd/lib/upload/interface";

import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

import PageHeader from "../../../components/PageHeader";
import { FormProvider } from "../../../context/Form";
import { useNextExercise, usePrevExercise } from "../../../context/Exercises";

import ExerciseForm from "./Form";

interface InputVariables extends Partial<IExercise> {
  uploads?: UploadFile[];
  fitness?: {
    google?: {
      exercise?: string;
      resistance_type?: string;
    };
  };
}

type ExerciseFormProviderProps<FormFields> = {
  onSubmit: (input: FormFields) => void;
  initialValues?: FormFields;
};

const Exercise = React.memo(() => {
  const { t } = useTranslation();

  const {
    replace,
    location: { pathname },
  } = useHistory();

  const { exerciseId } = useParams<{
    exerciseId?: string;
  }>();

  const [exerciseData, loading] = useDocumentDataOnce<IExercise>(
    exerciseId
      ? firebase.firestore().doc(`exercises/${exerciseId}`)
      : undefined,
    { idField: "id" }
  );

  const onSubmit = useCallback(
    (input: InputVariables) => {
      const { uploads, ...variables } = input;

      let inputVariables = { ...variables };

      if (!inputVariables?.fitness?.google?.exercise) {
        inputVariables = {
          ...inputVariables,
          fitness: omit(inputVariables?.fitness, ["google"]),
        };
      }
      if (!exerciseId) {
        inputVariables = {
          ...inputVariables,
          sharedWith: ["everyone"],
          published: false,
        };

        return firebase
          .firestore()
          .collection("exercises")
          .add(inputVariables)
          .then((snapshot) => {
            message.success(t("exercise:message.success.create.title"));
            return replace(`/exercise/${snapshot.id}`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      }
    },
    [exerciseId, replace, t]
  );

  const prevExercise = usePrevExercise(exerciseId);
  const nextExercise = useNextExercise(exerciseId);

  return (
    <>
      <PageHeader
        title={exerciseData?.name || t("exercise:title")}
        breadcrumb={{
          routes: [
            {
              path: "/exercise",
              breadcrumbName: t("exercises:title"),
            },
            {
              breadcrumbName: exerciseData?.name || t("exercise:title"),
              path: pathname,
            },
          ],
        }}
        extra={[
          prevExercise ? (
            <Link
              to={pathname.replace(exerciseId ?? "", prevExercise.id)}
              key="prev"
              aria-disabled={!prevExercise}
            >
              <Button type="default">{t("exercise:button.previous")}</Button>
            </Link>
          ) : (
            <Button type="default" key="prev" disabled>
              {t("exercise:button.previous")}
            </Button>
          ),
          nextExercise ? (
            <Link
              to={pathname.replace(exerciseId ?? "", nextExercise.id)}
              key="next"
              aria-disabled={!nextExercise}
            >
              <Button type="default">{t("exercise:button.next")}</Button>
            </Link>
          ) : (
            <Button type="default" key="next" disabled>
              {t("exercise:button.next")}
            </Button>
          ),
        ]}
      />

      <Layout.Content className="site-layout-content">
        <Skeleton loading={loading}>
          <FormProvider
            layout="vertical"
            requiredMark="optional"
            onFinish={onSubmit}
            initialValues={exerciseData}
          >
            <ExerciseForm />
          </FormProvider>
        </Skeleton>
      </Layout.Content>
    </>
  );
});

export default Exercise;
