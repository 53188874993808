import React, { useEffect } from "react";

import clsx from "clsx";
import { Avatar, Dropdown, Layout, Menu, Badge } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import firebase from "firebase";
import useMedia from "react-use/lib/useMedia";
import useMeasure from "react-use/lib/useMeasure";
import useToggle from "react-use/lib/useToggle";

import { useAuthState } from "react-firebase-hooks/auth";

import { ExercisesProvider } from "../../../context/Exercises";

import Sidebar from "./Sidebar";
import { useTranslation } from "react-i18next";

const { Header } = Layout;

const UserMenu = () => {
  const [user] = useAuthState(firebase.auth());

  const onLogout = () => {
    firebase.auth().signOut();
  };

  const { t } = useTranslation();

  const menu = (
    <Menu style={{ minWidth: 160 }}>
      <Menu.Item key="logout" onClick={onLogout} danger>
        {t("logout:logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Badge count={0}>
        <Avatar shape="circle" size={32}>
          {user?.displayName?.[0]}
        </Avatar>
      </Badge>
    </Dropdown>
  );
};

const LayoutPrivate: React.FunctionComponent = ({ children }) => {
  const isSmall = useMedia("(max-width: 767px)");

  const [collapsed, toggle] = useToggle(isSmall);

  useEffect(() => {
    if (isSmall) {
      toggle(true);
    }
  }, [isSmall]);

  return (
    <ExercisesProvider>
      <Layout className="site-layout-main">
        <Header className="site-layout-header">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
              style: { color: "#fff" },
            }
          )}
          <UserMenu />
        </Header>
        <Sidebar collapsed={collapsed} toggle={toggle} />
        <Layout
          //@ts-ignore
          className={clsx("site-layout", {
            open: !collapsed,
          })}
        >
          {children}
        </Layout>
      </Layout>
    </ExercisesProvider>
  );
};

export default LayoutPrivate;
