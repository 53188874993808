import React, { useContext } from "react";

import firebase from "firebase";
import findIndex from "lodash/findIndex";

import { useCollectionData } from "react-firebase-hooks/firestore";

interface ExercisesContextValue {
  data: IExercise[];
  loading: boolean;
}

const ExercisesContext = React.createContext<ExercisesContextValue>({
  data: [],
  loading: false,
});

const { Provider } = ExercisesContext;

export const useExercisesContext = () => useContext(ExercisesContext);
export const useExercises = () => {
  const { data } = useExercisesContext();

  return data;
};

export const useExercise = (id?: string) => {
  const { data } = useExercisesContext();

  const currentIndex = findIndex(data, { id });

  if (currentIndex === -1) {
    return;
  }

  return data[currentIndex];
};

export const usePrevExercise = (id?: string) => {
  const { data } = useExercisesContext();

  const currentIndex = findIndex(data, { id });

  if (currentIndex === -1) {
    return;
  }

  return data[currentIndex - 1];
};

export const useNextExercise = (id?: string) => {
  const { data } = useExercisesContext();

  const currentIndex = findIndex(data, { id });

  if (currentIndex === -1) {
    return;
  }

  return data[currentIndex + 1];
};

export const ExercisesProvider = React.memo(({ children }) => {
  const [data, loading] = useCollectionData<IExercise>(
    firebase.firestore().collection("exercises"),
    {
      idField: "id",
    }
  );

  // const data: IExercise[] = [];
  // const loading = false;

  return <Provider value={{ data: data || [], loading }}>{children}</Provider>;
});
