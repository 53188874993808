import React, { useMemo, useState } from "react";

import firebase from "firebase";

import map from "lodash/map";

import { Button, Layout, Popconfirm, Space, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";

import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import PageHeader from "../../../components/PageHeader";
import {
  ExerciseEquipment,
  ExerciseMuscleGroup,
  ExerciseType,
} from "../../../config/const";
import { useExercisesContext } from "../../../context/Exercises";

import Fuse from "fuse.js";

const ActionDelete = ({ data: { id } }: { data: IExercise }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const onDelete = () => {
    setLoading(true);

    firebase
      .firestore()
      .doc(`exercises/${id}`)
      .delete()
      .finally(() => setLoading(false));
  };

  return (
    <Popconfirm
      placement="topLeft"
      title={t("exercises:dialog.delete.title")}
      onConfirm={() => onDelete()}
      okText={t("exercises:dialog.delete.button.confirm")}
      okType="danger"
      cancelText={t("exercises:dialog.delete.button.dismiss")}
      disabled={loading}
    >
      <Button type="text" danger disabled={loading}>
        {t("exercises:table.button.delete")}
      </Button>
    </Popconfirm>
  );
};

const useColumns = () => {
  const { t } = useTranslation();

  const exerciseTypeFilter = map(ExerciseMuscleGroup, (value) => ({
    value,
    text: t(`exerciseType:${value}`),
  }));

  const exerciseEquipmentFilter = map(ExerciseEquipment, (value) => ({
    value,
    text: t(`equipment:${value}`),
  }));

  const exerciseMuscleGroupFilter = map(ExerciseMuscleGroup, (value) => ({
    value,
    text: t(`muscleGroup:${value}`),
  }));

  const publishedFilter = [
    { value: true, text: t("exercise:update.published.true") },
    { value: false, text: t("exercise:update.published.false") },
  ];

  // const fieldNameProps = useSearchableColumn("name");

  const columns: ColumnsType<IExercise> = [
    {
      dataIndex: "name",
      title: t("exercises:table.header.name"),
      render: (value: string, data) => (
        <Link to={`/exercise/${data.id}`}>{value}</Link>
      ),
      // ...fieldNameProps,
    },
    {
      dataIndex: "exerciseType",
      title: t("exercises:table.header.exerciseType"),
      render: (value) => <Trans i18nKey={`exerciseType:${value}`} />,
      filters: exerciseTypeFilter,
      onFilter: (value, record) => record.exerciseType === value,
    },
    {
      dataIndex: "muscleGroup",
      title: t("exercises:table.header.muscleGroup"),
      render: (value) => <Trans i18nKey={`muscleGroup:${value}`} />,
      filters: exerciseMuscleGroupFilter,
      onFilter: (value, record) => record.muscleGroup === value,
    },

    {
      dataIndex: "equipment",
      title: t("exercises:table.header.equipment"),
      render: (value) => <Trans i18nKey={`equipment:${value}`} />,
      filters: exerciseEquipmentFilter,
      onFilter: (value, record) => record.equipment === value,
    },
    {
      dataIndex: "published",
      title: t("exercise:update.published.true"),
      render: (value) => (
        <Trans i18nKey={`exercise:update.published.${value}`} />
      ),
      filters: publishedFilter,
      onFilter: (value, record) => record.published === value,
    },
    {
      title: t("exercises:table.header.actions"),
      key: "actions",
      render: (_value: any, data) => (
        <Space size="middle">
          <ActionDelete data={data} />
        </Space>
      ),
    },
  ];

  return columns;
};

const Exercises = React.memo(() => {
  const { t } = useTranslation(["exercises"]);

  const columns = useColumns();

  const { data, loading } = useExercisesContext();

  const [search, setSearch] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const filteredData = useMemo(() => {
    const needle = search.trim();

    if (needle.length == 0) {
      return data;
    }

    const fuseData = new Fuse(data, {
      keys: [
        // "name",
        // "i18n.sr.name",
        {
          name: "name",
          getFn: (item) => {
            return [item.name, ...map(item.i18n, (value) => value.name)].join(
              " "
            );
          },
        },
      ],
      includeScore: true,
      isCaseSensitive: false,
    });

    const searchResult = fuseData.search(search);

    return searchResult.map((a) => a.item);
  }, [search, data]);

  return (
    <>
      <PageHeader
        title={t("exercises:title")}
        breadcrumb={{
          routes: [
            {
              path: "/",
              breadcrumbName: t("exercises:title"),
            },
          ],
        }}
        extra={[
          <Space key="search">
            <Input
              placeholder={t("search:search")}
              onChange={handleChange}
              value={search}
            />
            <Link key="exercise-create" to="/exercise/new">
              <Button type="primary">{t("exercises:button.add")}</Button>
            </Link>
          </Space>,
        ]}
      />

      <Layout.Content className="site-layout-content">
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          loading={loading}
          scroll={{ x: true }}
          // onChange={onChange}
        />
      </Layout.Content>
    </>
  );
});

export default Exercises;
