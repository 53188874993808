export const ExerciseType = {
  WEIGHTED_REPS: "1",
  BODYWEIGHT: "2",
  ASSISTED_BODYWEIGHT: "3",
  DISTANCE: "4",
  DURATION: "5",
  WEIGHTED_BODYWEIGHT: "6",
  DURATION_DISTANCE: "7",
  DURATION_REPS: "8",
};

export const ExerciseEquipment = {
  BARBELL: "1",
  DUMBBELL: "2",
  MACHINE_OTHER: "3",
  WEIGHTED_BODYWEIGHT: "4",
  ASSISTED_BODYWEIGHT: "5",
  WEIGHTED_REPS: "6",
  CARDIO: "7",
  DURATION: "8",
  KETTLEBELL: "9",
  BAND: "10",
  BALL: "11",
};

export const ExerciseMuscleGroup = {
  CHEST: "1",
  CARDIO: "2",
  SHOULDERS: "3",
  CORE: "4",
  ARMS: "5",
  BACK: "6",
  LEGS: "7",
  OTHER: "8",
  OLYMPIC: "9",
  FULL_BODY: "10",
};

export const ExerciseTypeFields = {
  [ExerciseType.WEIGHTED_REPS]: ["weight", "repetitions"],
  [ExerciseType.BODYWEIGHT]: ["repetitions"],
  [ExerciseType.ASSISTED_BODYWEIGHT]: ["assistance", "repetitions"],
  [ExerciseType.WEIGHTED_BODYWEIGHT]: ["resistance", "repetitions"],
  [ExerciseType.DISTANCE]: ["distance", "duration"],
  [ExerciseType.DURATION]: ["duration"],
  [ExerciseType.DURATION_DISTANCE]: ["distance", "duration"],
  [ExerciseType.DURATION_REPS]: ["repetitions", "duration"],
};

export const EXERCISE_TYPE_REQUIRED_FIELDS = {
  [ExerciseType.WEIGHTED_REPS]: ["weight", "repetitions"],
  [ExerciseType.BODYWEIGHT]: ["repetitions"],
  [ExerciseType.ASSISTED_BODYWEIGHT]: ["repetitions"],
  [ExerciseType.WEIGHTED_BODYWEIGHT]: ["repetitions"],
  [ExerciseType.DISTANCE]: ["distance"],
  [ExerciseType.DURATION]: ["duration"],
  [ExerciseType.DURATION_DISTANCE]: ["distance", "duration"],
  [ExerciseType.DURATION_REPS]: ["repetitions", "duration"],
};
