import React from "react";

import { Row, Col, Divider, Typography, Input, Tabs, Button, Form } from "antd";

import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

import * as Select from "../../../components/Select";

const { TabPane } = Tabs;

const { Title } = Typography;

const ExerciseForm = React.memo(() => {
  const { t } = useTranslation();

  const {
    location: { pathname },
  } = useHistory();

  const match = useRouteMatch();

  return (
    <>
      <Tabs activeKey={pathname}>
        <TabPane key={match.url}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} xxl={8}>
              <Title level={5}>{t("exercise:section.title.details")}</Title>
              <Divider className="mt-0" />
              <Form.Item
                label={t("exercise:input.label.name")}
                name={["name"]}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("exercise:input.label.exerciseType")}
                name={["exerciseType"]}
                rules={[{ required: true }]}
              >
                <Select.ExerciseType showSearch />
              </Form.Item>
              <Form.Item
                label={t("exercise:input.label.equipment")}
                name={["equipment"]}
                rules={[{ required: true }]}
              >
                <Select.ExerciseEquipment showSearch />
              </Form.Item>
              <Form.Item
                label={t("exercise:input.label.muscleGroup")}
                name={["muscleGroup"]}
                rules={[{ required: true }]}
              >
                <Select.ExerciseMuscleGroup showSearch />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} xxl={8}>
              <Title level={5}>{t("exercise:section.title.googleFit")}</Title>
              <Divider className="mt-0" />
              <Form.Item
                label={t("exercise:input.label.fitness.google.exercise")}
                name={["fitness", "google", "exercise"]}
                rules={[{ required: false }]}
              >
                <Select.GoogleFitExercise showSearch />
              </Form.Item>
              <Form.Item
                label={t("exercise:input.label.fitness.google.resistance_type")}
                name={["fitness", "google", "resistance_type"]}
                rules={[{ required: false }]}
              >
                <Select.GoogleFitResistanceType showSearch />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>
      </Tabs>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("exercise:button.submit")}
        </Button>
      </Form.Item>
    </>
  );
});

export default ExerciseForm;
