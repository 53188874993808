import React, { useMemo } from "react";

import map from "lodash/map";
import groupBy from "lodash/groupBy";
import camelCase from "lodash/camelCase";

import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { useTranslation } from "react-i18next";

import { useExercises } from "../../context/Exercises";

import {
  ExerciseType as ExerciseTypeNumber,
  ExerciseEquipment as ExerciseEquipmentNumber,
  ExerciseMuscleGroup as ExerciseMuscleGroupNumber,
} from "../../config/const";
import {
  ResistanceType as ResistanceTypeValues,
  WorkoutExercise as WorkoutExerciseValues,
} from "../../config/google-fit";

export const useExerciseTypeOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      map(ExerciseTypeNumber, (key) => ({
        label: t(`exerciseType:${key}`),
        value: key,
      })),
    [t]
  );
};

export const useExerciseMuscleGroupOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      map(ExerciseMuscleGroupNumber, (key: string) => ({
        label: t(`muscleGroup:${key}`),
        value: key,
      })),
    [t]
  );
};

export const useExerciseEquipmentOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      map(ExerciseEquipmentNumber, (key: string) => ({
        label: t(`equipment:${key}`),
        value: key,
      })),
    [t]
  );
};

export const useGoogleFitExerciseOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      map(map(WorkoutExerciseValues, camelCase), (key) => ({
        label: t(`googleFit:exercise.${key}`),
        value: key,
      })),
    [t]
  );
};

export const useGoogleFitResistanceTypeOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      map(ResistanceTypeValues, (key) => ({
        label: t(`googleFit:resistance_type.${key}`),
        value: key,
      })),
    [t]
  );
};

export const ExerciseType = (props: SelectProps<string>) => {
  const options = useExerciseTypeOptions();

  return <Select {...props} options={options} />;
};

export const ExerciseMuscleGroup = (props: SelectProps<string>) => {
  const options = useExerciseMuscleGroupOptions();

  return <Select {...props} options={options} />;
};

export const ExerciseEquipment = (props: SelectProps<string>) => {
  const options = useExerciseEquipmentOptions();

  return <Select {...props} options={options} />;
};

export const Exercises = (props: SelectProps<string>) => {
  const { t } = useTranslation();

  const exercises = useExercises();

  const grouped = useMemo(
    () => groupBy(exercises, ({ exerciseType }) => exerciseType),
    [exercises]
  );

  const options = useMemo(
    () =>
      map(grouped, (options, key) => ({
        label: t(`exerciseType:${key}`),
        options: map(options, ({ name: value, name: label }) => ({
          label,
          value,
        })),
      })),
    [t, grouped]
  );

  return <Select {...props} options={options} />;
};

export const GoogleFitExercise = (props: SelectProps<string>) => {
  const options = useGoogleFitExerciseOptions();

  return <Select {...props} options={options} />;
};

export const GoogleFitResistanceType = (props: SelectProps<string>) => {
  const options = useGoogleFitResistanceTypeOptions();

  return <Select {...props} options={options} />;
};
