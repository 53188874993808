import React from "react";

import { Layout } from "antd";

import PageHeader from "../../components/PageHeader";

import { useTranslation } from "react-i18next";

const Dashboard = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader
        title={t("sidebar:navigation.link.dashboard")}
        breadcrumb={{
          routes: [
            {
              path: "/",
              breadcrumbName: t("sidebar:navigation.link.dashboard"),
            },
          ],
        }}
      />

      <Layout.Content className="site-layout-content" />
    </>
  );
});

export default Dashboard;
