import React, { useCallback, useEffect } from "react";

import firebase from "firebase";

import { Form, Layout, Row, Col, Typography, Divider, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

import PageHeader from "../../../components/PageHeader";

import { FormProvider } from "../../../context/Form";

const { Title } = Typography;

const User = React.memo(() => {
  const { t } = useTranslation();

  const { userId } = useParams<{ userId?: string }>();

  const [userData] = useDocumentDataOnce(
    userId ? firebase.firestore().doc(`users/${userId}`) : undefined
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (userData) {
      form.setFieldsValue(userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const {
    location: { pathname },
  } = useHistory();

  const onSubmit = useCallback((variables: any) => {
    console.log(variables);
  }, []);

  return (
    <>
      <PageHeader
        title={t("users:user")}
        breadcrumb={{
          routes: [
            {
              path: "/user",
              breadcrumbName: t("users:title"),
            },
            {
              breadcrumbName: t("users:user"),
              path: pathname,
            },
          ],
        }}
      />

      <Layout.Content className="site-layout-content">
        <FormProvider
          layout="vertical"
          requiredMark="optional"
          onFinish={onSubmit}
          form={form}
          initialValues={{
            email: "",
            sessions: [],
            measurements: [],
            favoriteExercises: [],
            unit: "metric",
            sessionSync: false,
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} xxl={12}>
              <Title level={5}>{t("workout:section.title.details")}</Title>
              <Divider className="mt-0" />
              <Form.Item
                label={t("user:input.label.email")}
                name={["email"]}
                rules={[{ required: true }]}
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                label={t("user:input.label.unit")}
                name={["unit"]}
                rules={[{ required: true }]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
        </FormProvider>
      </Layout.Content>
    </>
  );
});

export default User;
