import React, { useMemo, useCallback } from "react";
import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  SnippetsOutlined,
  UserOutlined,
} from "@ant-design/icons";

import useMedia from "react-use/lib/useMedia";

import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = ({
  collapsed,
  toggle,
}: {
  collapsed: boolean;
  toggle: (state?: boolean) => void;
}) => {
  const isSmall = useMedia("(max-width: 767px)");

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const defaultSelectedKeys = useMemo(
    () => [`/${pathname.split("/")[1] || ""}`],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onBeforeNavigate = useCallback(() => {
    if (isSmall) {
      console.log(isSmall);
      toggle(true);
    }
  }, [isSmall, toggle]);

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      collapsedWidth={0}
      trigger={null}
      width={isSmall ? "100%" : 200}
      className="site-layout-sidebar"
    >
      <Menu mode="inline" defaultSelectedKeys={defaultSelectedKeys}>
        <Menu.Item key="/" icon={<DashboardOutlined />}>
          <NavLink exact to="/" onClick={onBeforeNavigate}>
            {t("sidebar:navigation.link.dashboard")}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/exercise" icon={<SnippetsOutlined />}>
          <NavLink to="/exercise" onClick={onBeforeNavigate}>
            {t("exercises:title")}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/workout" icon={<SnippetsOutlined />}>
          <NavLink to="/workout" onClick={onBeforeNavigate}>
            {t("workouts:title")}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/user" icon={<UserOutlined />}>
          <NavLink to="/user" onClick={onBeforeNavigate}>
            {t("users:title")}
          </NavLink>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
