import React, { useMemo } from "react";

import map from "lodash/map";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";

import axios from "axios";
import qs from "query-string";

import { useQuery } from "react-query";

import {
  Form,
  Image,
  Select as AntSelect,
  Row,
  Col,
  Divider,
  Typography,
  AutoComplete,
  Button,
} from "antd";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useSharedForm } from "../../../context/Form";

const { Title } = Typography;

const MediaPreview = () => {
  const { lang } = useParams<{ lang?: string }>();
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prev, next) => isEqual(prev.media, next.media)}
    >
      {({ getFieldValue }) => {
        const media: {
          type: TExerciseMediaType;
          url: string;
        } = getFieldValue(lang ? ["i18n", lang, "media"] : ["media"]);

        if (media?.type === "image" && media?.url?.endsWith("mp4")) {
          return (
            <div className="ratio ratio-16x9 mb-4 mt-2" key={media.url}>
              <video controls>
                <source src={media.url} type="video/mp4" />
              </video>
            </div>
          );
        }

        if (media?.type === "image" && media?.url) {
          return (
            <Image className="mb-4 mt-2" key={media.url} src={media.url} />
          );
        }

        if (media?.type === "youtube" && media?.url) {
          return (
            <div className="ratio ratio-16x9 mb-4 mt-2" key={media.url}>
              <iframe
                id="player"
                title="player"
                // prettier-ignore
                src={`https://www.youtube.com/embed/${media.url.split("v=")[1]}?enablejsapi=1&`}
                frameBorder="0"
              />
            </div>
          );
        }

        return null;
      }}
    </Form.Item>
  );
};

const ExerciseMedia = React.memo(() => {
  const { t } = useTranslation();

  const { lang } = useParams<{ lang?: string }>();
  const { data } = useQuery("todos", () =>
    axios
      .get<string[]>("/exercises/exercises.json", {})
      .then(({ data }) => data)
  );

  const groupedOptions = useMemo(
    () =>
      groupBy(data, (v) => {
        if (v.includes("160x160")) {
          return "list";
        }

        if (v.includes("360x200")) {
          return "card";
        }

        return "media";
      }),
    [data]
  );

  const autoCompleteThumbnailListOptions = useMemo(
    () =>
      map(groupedOptions["list"], (value) => ({
        value,
        label: new URL(value).pathname.replace("/exercises/", ""),
      })),
    [groupedOptions]
  );

  const autoCompleteThumbnailCardOptions = useMemo(
    () =>
      map(groupedOptions["card"], (value) => ({
        value,
        label: new URL(value).pathname.replace("/exercises/", ""),
      })),
    [groupedOptions]
  );

  const autoCompleteThumbnailMediaOptions = useMemo(
    () =>
      map(groupedOptions["media"], (value) => ({
        value,
        label: new URL(value).pathname.replace("/exercises/", ""),
      })),
    [groupedOptions]
  );

  const form = useSharedForm();

  const onChange = (value: any) => {
    form.setFieldsValue({
      media: {
        url: "",
      },
    });
  };

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={12} xxl={8}>
        <Title level={5}>{t("exercise:section.title.media")}</Title>
        <Divider className="mt-0" />
        <Form.Item
          label={t("exercise:input.label.media.type")}
          name={lang ? ["i18n", lang, "media", "type"] : ["media", "type"]}
          rules={[{ required: false }]}
        >
          <AntSelect onChange={onChange}>
            <AntSelect.Option value="image">
              {t("exercise:select.option.media.image")}
            </AntSelect.Option>
            <AntSelect.Option value="youtube">
              {t("exercise:select.option.media.youtube")}
            </AntSelect.Option>
          </AntSelect>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => !isEqual(prev.media, next.media)}
        >
          {({ getFieldValue }) => {
            const type: TExerciseMediaType = getFieldValue(
              lang ? ["i18n", lang, "media", "type"] : ["media", "type"]
            );

            return (
              <Form.Item
                label={t("exercise:input.label.media.url")}
                name={lang ? ["i18n", lang, "media", "url"] : ["media", "url"]}
                rules={[{ required: false, type: "url" }]}
                help={t("exercise:input.helper.media.url")}
                className="mb-4"
              >
                <AutoComplete
                  defaultValue=""
                  options={autoCompleteThumbnailMediaOptions}
                  filterOption={(inputValue, option) =>
                    option!.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={(value) => {
                    if (value && type === "youtube") {
                      const {
                        query: { v: key },
                      } = qs.parseUrl(value);

                      if (!key) {
                        return;
                      }
                    }

                    if (value && type === "image") {
                      const key = value.split("/").pop()?.split("-")[0];

                      if (!key) {
                        return;
                      }
                      if (lang) {
                        form.setFieldsValue({
                          i18n: {
                            lang: {
                              thumbnail: {
                                list: `https://img.youtube.com/vi/${key}/default.jpg`,
                                card: `https://img.youtube.com/vi/${key}/mqdefault.jpg`,
                              },
                            },
                          },
                        });
                      } else {
                        form.setFieldsValue({
                          thumbnail: {
                            list: `https://img.youtube.com/vi/${key}/default.jpg`,
                            card: `https://img.youtube.com/vi/${key}/mqdefault.jpg`,
                          },
                        });
                      }

                      const thumbList = autoCompleteThumbnailListOptions.find(
                        ({ value: fileName }) => fileName.includes(key)
                      );

                      const thumbCard = autoCompleteThumbnailCardOptions.find(
                        ({ value: fileName }) => fileName.includes(key)
                      );

                      if (!(thumbList?.value && thumbCard?.value)) {
                        return;
                      }
                    }
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <MediaPreview />
        <Form.Item>
          <Button
            style={{ textAlign: "center" }}
            type="primary"
            htmlType="submit"
          >
            {t("exercise:button.submitEditing")}
          </Button>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} xxl={8}>
        <Title level={5}>{t("exercise:section.title.thumbnails")}</Title>
        <Divider className="mt-0" />
        <>
          <Form.Item
            label={t("exercise:input.label.thumbnail.list")}
            name={
              lang ? ["i18n", lang, "thumbnail", "list"] : ["thumbnail", "list"]
            }
            initialValue={""}
            rules={[{ required: false, type: "url" }]}
            help={t("exercise:input.helper.thumbnail.list")}
          >
            <AutoComplete
              options={autoCompleteThumbnailListOptions}
              filterOption={(inputValue, option) =>
                option!.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              isEqual(prev?.thumbnail?.list, next.thumbnail?.list)
            }
          >
            {({ getFieldValue }) => {
              const source: string = getFieldValue(
                lang
                  ? ["i18n", lang, "thumbnail", "list"]
                  : ["thumbnail", "list"]
              );
              if (!source) {
                return null;
              }

              return <Image key={source} src={source} width={80} />;
            }}
          </Form.Item>
        </>

        <>
          <Form.Item
            label={t("exercise:input.label.thumbnail.card")}
            name={
              lang ? ["i18n", lang, "thumbnail", "card"] : ["thumbnail", "card"]
            }
            initialValue={""}
            rules={[{ required: false, type: "url" }]}
            help={t("exercise:input.helper.thumbnail.card")}
          >
            <AutoComplete
              options={autoCompleteThumbnailCardOptions}
              filterOption={(inputValue, option) =>
                option!.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              isEqual(prev?.thumbnail?.card, next.thumbnail?.card)
            }
          >
            {({ getFieldValue }) => {
              const source: string = getFieldValue(
                lang
                  ? ["i18n", lang, "thumbnail", "card"]
                  : ["thumbnail", "card"]
              );
              if (!source) {
                return null;
              }

              return <Image key={source} src={source} width={320} />;
            }}
          </Form.Item>
        </>
      </Col>
    </Row>
  );
});

export default ExerciseMedia;
