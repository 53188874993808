import dayjs, { Dayjs } from "dayjs";
import i18n from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";

import { initReactI18next } from "react-i18next";

import en from "./en/common.json";
import sr from "./sr/common.json";

const resources = { en, sr };

i18n
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    resources,

    debug: process.env.NODE_ENV !== "production",

    lng: "en",
    fallbackLng: "sr",

    keySeparator: ".",

    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (value instanceof dayjs) {
          return dayjs(value as Dayjs).format(format);
        }

        switch (format) {
          case "uppercase": {
            return value.toUpperCase();
          }

          default: {
            return value;
          }
        }
      },
    },
  });

export default i18n;
