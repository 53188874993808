import firebase from "firebase/app";

import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";

// Development

const config = {
  apiKey: "AIzaSyBbdoM9WF-j1CkRpXnZSNT1LV_Ll4F_3_4",
  authDomain: "strong-425ca.firebaseapp.com",
  databaseURL: "https://strong-425ca.firebaseio.com",
  projectId: "strong-425ca",
  storageBucket: "strong-425ca.appspot.com",
  messagingSenderId: "495700114614",
  appId: "1:495700114614:web:13e9ceea9beb685f303a17",
  measurementId: "G-QKBSBMQQXX",
};

firebase.initializeApp(config);

if (window.location.hostname === "localhost") {
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.functions().useEmulator("localhost", 5002);
  firebase.firestore().useEmulator("localhost", 8082);
}

export default firebase;
