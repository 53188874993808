import React from "react";

import firebase from "firebase";

import { Layout, Table } from "antd";
import { ColumnsType } from "antd/lib/table";

import { Link } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { useTranslation } from "react-i18next";

import PageHeader from "../../../components/PageHeader";

const columns: ColumnsType<IUser> = [
  {
    dataIndex: "id",
    title: "Id",
    width: 140,
    render: (value: string) => <Link to={`/user/${value}`}>{value}</Link>,
  },
  {
    dataIndex: "email",
    title: "E-mail",
  },
];

const Users = React.memo(() => {
  const [data, loading] = useCollectionData<IUser>(
    firebase.firestore().collection("users").limit(10),
    {
      idField: "id",
    }
  );

  const { t } = useTranslation();

  return (
    <>
      <PageHeader
        title={t("users:title")}
        breadcrumb={{
          routes: [
            {
              path: "/",
              breadcrumbName: t("users:title"),
            },
          ],
        }}
      />

      <Layout.Content className="site-layout-content">
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          loading={loading}
          scroll={{ x: true }}
          // onChange={onChange}
        />
      </Layout.Content>
    </>
  );
});

export default Users;
