import React, { useEffect } from "react";

import firebase from "firebase";
// import api from "../../lib/api";

// const AuthContext = React.createContext({});

// const { Provider } = AuthContext;

const AuthProvider: React.FC = ({ children }) => {
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        // delete api.defaults.headers.common.Authorization;

        return;
      }

      // user.getIdTokenResult(true).then((token) => {
      //   console.log(token);
      // });

      user
        .getIdToken(true)
        .then(() => {
          // api.defaults.headers.common.Authorization = `Bearer ${token}`;
        })
        .catch(console.error);
    });

    return unsubscribe;
  }, []);

  return <>{children}</>;
};

export default AuthProvider;
